/*
  IE11 support added with AutoPrevixer
  https://autoprefixer.github.io/
*/

.diagram {
    display: -ms-grid;
    display: grid;
    display: -ms-grid;
    display: grid;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(14, 1fr);
    align-items: start;
    justify-items: center;
    max-width: 7in;
    margin: .2in 0;
    width: 70%;
    margin: 0 auto;
  }
  .diagram > div {
    padding: 12px;
    border-radius: 12px;
    background: #f0eeee;
    border: 2px solid #4384da;
  }
  .diagram > .component {
    border-radius: 12px;
    background: #f0eeee;
    border: 2px solid #4384da;
  }
  .diagram > div h4 {
    font-size: 120%;
    font-weight: bold;
    margin: 0 0 6px 0;
  }
  .diagram > div p {
    font-size: 90%;
    margin: 0;
  }
  
  .raw-data-client {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/span 5;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .raw-data-server { 
    -ms-grid-column: 7; 
    -ms-grid-column-span: 5; 
    grid-column: 7/span 5;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .pivot-engine {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 4/span 5;
    -ms-grid-row: 3;
    grid-row: 3;
    /* background-color: #007BFF; */
    color: rgb(0, 0, 0);
  }
  .pivot-panel {
    -ms-grid-column: 10;
    -ms-grid-column-span: 5;
    grid-column: 10/span 5;
    -ms-grid-row: 3;
    grid-row: 3;
    /* background-color: #007BFF; */
    color: rgb(0, 0, 0);
  }
  .pivot-grid {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/span 5;
    -ms-grid-row: 5;
    grid-row: 5;
    /* background-color: #007BFF; */
    color: rgb(0, 0, 0);
  }
  .pivot-chart {
    -ms-grid-column: 7;
    -ms-grid-column-span: 5;
    grid-column: 7/span 5;;
    -ms-grid-row: 5;;
    grid-row: 5;
    /* background-color: #007BFF; */
    color: rgb(0, 0, 0);
  }
  
  .row-2 {
    -ms-grid-row: 2;
    grid-row: 2;
  }
  .row-3 {
    -ms-grid-row: 3;
    grid-row: 3;
  }
  .row-4 {
    -ms-grid-row: 4;
    grid-row: 4;
  }
  .col-4 {
    -ms-grid-column: 4;
    grid-column: 4;
  }
  .col-8 {
    -ms-grid-column: 8;
    grid-column: 8;
  }
  .col-9 {
    -ms-grid-column: 9;
    grid-column: 9;
  }
  
  .diagram .conn {
    position: relative;
    width: .5in;
    height: .5in;
    padding: 0;
    border: none;
    background: transparent;
    z-index: -1;
  }
  .diagram .conn svg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
    fill: #E6E0FF;
  }
  
  body {
    margin-bottom: 24pt;
  }
  
  .text-content {
    width: 70%;
    margin: 0 auto;
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) { /* Adjust the max-width as needed */
    .text-content {
        width: 100%; /* Set width to 100% on small screens */
    }

    .diagram {
        width: 100%; /* Set diagram width to 100% on small screens */
    }
  }